<template>
	<div style="width: 100vw; height: 100vh">
		<div class="status-bar" />
		<div class="" id='box'>
		</div>
		<iframe :src="src"></iframe>
		<div class="" id='box2'>
			
		</div>
	</div>
</template>

<script>
	var wv;
	export default {
		data() {
			return {
				src: ''
			}
		},
		created(option) {
			this.src = decodeURIComponent(this.$route.query.url)
		}
	}
</script>

<style>
</style>
